@font-face {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./gfonts-mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7A.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./gfonts-mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1AA.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat Alternates';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./gfonts-mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1AA.ttf) format('truetype');
}
