@font-face {
  font-family: Montserrat Alternates;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/gfonts-mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7A.2f6693f3.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat Alternates;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/gfonts-mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1AA.ba8a7dd2.ttf") format("truetype");
}

@font-face {
  font-family: Montserrat Alternates;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/gfonts-mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1AA.61cffed6.ttf") format("truetype");
}
/*# sourceMappingURL=index.14303494.css.map */
